import React, { useState, useEffect } from 'react';
import './ProjectModal.css';
import NewsArticles from './NewsArticles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

function ProjectModal({ project, onClose }) {
  const [showNews, setShowNews] = useState(false);

  useEffect(() => {
    // Prevent body scroll when the modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Restore body scroll when the modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleToggleNews = () => {
    setShowNews(!showNews);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        style={{
          backgroundImage: project.backgroundImage
            ? `url(${project.backgroundImage})`
            : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        onClick={stopPropagation}
        onTouchMove={stopPropagation} // Prevent touch scroll propagation
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <div className="modal-header">
          <img
            src={project.logo}
            alt={`${project.name} logo`}
            className="project-logo"
          />
          <h3
            className={project.backgroundImage ? 'project-title-with-bg' : ''}
          >
            {project.name}
          </h3>
        </div>

        <div className="content-container">
          <button onClick={handleToggleNews} className="show-news-button">
            {showNews ? 'Hide News' : 'Show Latest News'}
          </button>

          <div className="content-box">
            {!showNews
              ? documentToReactComponents(project.description)
              : <NewsArticles projectName={project.name} />
            }
          </div>
        </div>

        <div className="modal-footer">
          <div className="social-icons">
            {project.webUrl && (
              <a href={project.webUrl} target="_blank" rel="noopener noreferrer">
                <img src="web.png" alt="Web" className="social-icon" />
              </a>
            )}
            {project.xUrl && (
              <a href={project.xUrl} target="_blank" rel="noopener noreferrer">
                <img src="x.png" alt="X" className="social-icon" />
              </a>
            )}
            {project.telegramUrl && (
              <a href={project.telegramUrl} target="_blank" rel="noopener noreferrer">
                <img src="telegram.png" alt="Telegram" className="social-icon" />
              </a>
            )}
            {project.discordUrl && (
              <a href={project.discordUrl} target="_blank" rel="noopener noreferrer">
                <img src="discord.png" alt="Discord" className="social-icon" />
              </a>
            )}
            {project.cyratorUrl && (
              <a href={project.cyratorUrl} target="_blank" rel="noopener noreferrer">
                <img src="Cyrator.png" alt="Cyrator" className="social-icon" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
