import React, { useState, useEffect } from 'react';
import client from './contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './NewsArticles.css';

function NewsArticles({ projectName }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client.getEntries({
      content_type: 'coinwebprojectsnews',  // Ensure this matches your content type ID
      'fields.project': projectName,
      order: '-fields.date',
    })
      .then((response) => {
        if (response.items.length === 0) {
          setError('No news articles found for this project.');
        } else {
          setArticles(response.items);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching news:', error);
        setError('Failed to load news articles.');
        setLoading(false);
      });
  }, [projectName]);

  const options = {
    renderNode: {
      'embedded-asset-block': (node) => (
        <img
          src={node.data.target.fields.file.url}
          alt={node.data.target.fields.title}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      ),
      'hyperlink': (node) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      ),
    },
  };

  if (loading) {
    return <p>Loading news...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="news-articles-container">
      {articles.map((article) => (
        <div key={article.sys.id} className="news-article" style={{ marginBottom: '20px' }}>
          {/* Render the Title */}
          <h3>{article.fields.title ? documentToReactComponents(article.fields.title) : 'No Title'}</h3>
          
          {/* Render the Date */}
          <p><strong>{article.fields.date ? new Date(article.fields.date).toLocaleDateString() : 'No Date'}</strong></p>
          
          {/* Render the Description with custom link rendering */}
          <div>
            {article.fields.description ? documentToReactComponents(article.fields.description, options) : 'No Description Available'}
          </div>
          
          <hr style={{ margin: '20px 0', border: '1px solid #ddd' }} />
        </div>
      ))}
    </div>
  );
}

export default NewsArticles;
