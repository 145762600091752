import React, { useState, useEffect } from 'react';
import './App.css';
import ProjectModal from './ProjectModal';
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary
import { fetchProjects } from './fetchProjects'; // Import the function to fetch projects

const flyInAnimations = [
  'flyInFromLeft',
  'flyInFromRight',
  'flyInFromTop',
  'flyInFromBottom'
];

const getRandomAnimation = () => {
  const randomIndex = Math.floor(Math.random() * flyInAnimations.length);
  return flyInAnimations[randomIndex];
};

function App() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [categoryAnimations, setCategoryAnimations] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const getProjects = async () => {
      const projectData = await fetchProjects();
      setProjects(projectData);

      const animations = projectData.map(() => getRandomAnimation());
      setCategoryAnimations(animations);
    };

    getProjects();
  }, []);

  // Count the number of projects in each category
  const categoryCounts = projects.reduce((acc, project) => {
    acc[project.category] = (acc[project.category] || 0) + 1;
    return acc;
  }, {});

  // Sort categories by the number of projects in descending order
  const categories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);

  const handleLogoClick = (project) => {
    setSelectedProject(project);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedProject(null);
  };

  return (
    <div className="App">
      <div className="ecosystem-bar">
        Coinweb Ecosystem
      </div>
      <div className="categories">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category expanded ${categoryAnimations[index]}`}
          >
            <button className="category-button">{category}</button>
            <div className="logos">
              {projects
                .filter(project => project.category === category)
                .map((project, index) => (
                  <div key={index} className="project-item" onClick={() => handleLogoClick(project)}>
                    <img src={project.logo} alt={`${project.name} logo`} />
                    <span>{project.name}</span>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      {selectedProject && (
        <ErrorBoundary>
          <ProjectModal project={selectedProject} onClose={closeModal} />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default App;
